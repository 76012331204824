import React, { useEffect, useState } from 'react';
import { formatDate } from '../classes/formatDate'
import { Card } from '../components/Card'
import { Section } from '../components/Section'
import { SimpleLayout } from '../components/SimpleLayout'
import SEO from './../components/SEO'
import { client, ARTICLES_PAGE } from '../classes/gqlClient';

function ArticleSection({ children, ...props }) {
    return (
        <Section {...props}>
            <div className="space-y-16">{children}</div>
        </Section>
    )
}

function Article({ article }) {
    return (
        <Card as="article">
            <Card.Title isA="true" href={`https://theadeyemiolayinka.hashnode.dev/${article.slug}`}>
                {article.title}
            </Card.Title>
            <Card.Eyebrow as="time" dateTime={article.publishedAt} decorate>
                {formatDate(article.publishedAt)}
            </Card.Eyebrow>
            <Card.Description>{article.brief}</Card.Description>
            <Card.Cta>Read article</Card.Cta>
        </Card>
    )
}

export default function ArticlesPage() {
    const [articles, setArticles] = useState([]);
    const [page, setPage] = useState(1);
    const [hasLoadedArticles, setHasLoadedArticles] = useState(false);
    const [noMoreArticles, setNoMoreArticles] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const loadMoreArticles = () => {
        if (isLoading) return;
        setIsLoading(true);
        client
            .query({
                query: ARTICLES_PAGE(page),
            })
            .then((result) => {
                if (!result.data.user.posts) {
                    setNoMoreArticles(true);
                    setHasLoadedArticles(true);
                    return
                }
                else if (!result.data.user.posts.nodes) {
                    setNoMoreArticles(true);
                    setHasLoadedArticles(true);
                    return
                }
                setHasLoadedArticles(true);
                setPage((prevPage) => prevPage + 1); 
                setArticles((prevArticles) => {
                    const newArticles = [...prevArticles, ...result.data.user.posts.nodes];
                    const uniqueArticles = Array.from(new Set(newArticles.map(article => article.cuid)))
                        .map(cuid => newArticles.find(article => article.cuid === cuid));
                    return uniqueArticles;
                    
                });
                setIsLoading(false);
                if (!result.data.user.posts){
                    setNoMoreArticles(true);
                }
                else if (!result.data.user.posts.nodes){
                    setNoMoreArticles(true);
                }
                else if (result.data.user.posts.nodes.length < 1) {
                    setNoMoreArticles(true);
                }
            });
    };

    useEffect(() => {
        loadMoreArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    window.onscroll = () => {
        if (
            window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 &&
            articles.length > 0 
        ) {
            if (!noMoreArticles){
                loadMoreArticles();
            }
        }
    };
    return (
        <>
            <SEO
                title='Articles | TheAdeyemiOlayinka'
                description='Explore a diverse collection of articles, insights, and knowledge sharing. Stay updated with my thoughts and experiences in the world of technology.'
                name='TheAdeyemiOlayinka.'
                type='profile' />
            <SimpleLayout
                title="Discover my latest articles and publications in the tech world."
                intro="Explore a diverse collection of articles, insights, and knowledge sharing. Stay updated with my thoughts and experiences in the world of technology."
            >
                <div className="space-y-20">
                    <ArticleSection title="Publications">
                        {(hasLoadedArticles && articles.length < 1) &&
                            <div>
                                <Card.Row as="div" className="justify-center space-y-10">
                                    <center><h2 className="ml-3 mt-1 pt-1 text-base font-semibold text-zinc-800 dark:text-zinc-100">
                                        <Card.HacLink isA="noLink" href="#">No Articles Yet.</Card.HacLink>
                                    </h2></center>
                                </Card.Row>
                            </div>
                        }
                        {(!hasLoadedArticles && articles.length < 1) &&
                            <div>
                                <Card.Row as="div" className="justify-center">
                                    <center><h2 className="ml-3 mt-1 pt-1 text-base font-semibold text-zinc-800 dark:text-zinc-100">
                                        <Card.HacLink isA="noLink" href="#">Loading Articles...</Card.HacLink>
                                    </h2></center>
                                </Card.Row>
                            </div>
                        }
                        {articles.map((article, index) => (
                            <Article article={article} />
                        ))}
                        {(isLoading && articles.length > 0) &&
                            <div className="py-6">
                                <Card.Row as="div" className="justify-center">
                                    <center><h2 className="ml-3 mt-1 pt-1 text-base font-semibold text-zinc-800 dark:text-zinc-100">
                                        <Card.HacLink isA="noLink" href="#">Loading More Articles...</Card.HacLink>
                                    </h2></center>
                                </Card.Row>
                            </div>
                        }
                    </ArticleSection>
                </div>
            </SimpleLayout>
        </>
    )
}
