import React, { useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { SimpleLayout } from '../components/SimpleLayout'
import SEO from './../components/SEO'
import 'react-vertical-timeline-component/style.min.css';


function WorkIcon(props) {
    return (
        <img
            src={props.logo}
            alt=""
            className="rounded-full"
            loading="lazy"
            decoding="async"
            data-nimg="1"
            unoptimized
        />
    )
}

export default function ResumePage() {
    const [jsonData, setJsonData] = useState(null);

    let resume = {
        "work": [
            {
                "company": "Veegil Media",
                "title": "Backend Developer",
                "logo": "./uploads/exp/veegil.jpg",
                "start": "08/2023",
                "end": {
                    "label": "Present",
                    "dateTime": 2023
                },
                "feats": [
                    "Designed, developed and tested software applications.",
                    "Adapted and understood Agile and Scrum methodologies.",
                    "Managed Nest.js Microservices."
                ]
            },
            {
                "company": "Martech Stores",
                "title": "Fullstack Developer",
                "logo": "./uploads/exp/martech.png",
                "start": "08/2022",
                "end": {
                    "label": "Present",
                    "dateTime": 2023
                },
                "feats": [
                    "Developed the back-end Structure of the e-commerce website.",
                    "Tested programs and databases to identify issues and make necessary modifications.",
                    "Designed, developed, and implemented software applications for website based on analyzed requirements and understanding of industry technical standards."
                ]
            },
            {
                "company": "Kolhab Technologies",
                "title": "Fullstack Developer",
                "logo": "./uploads/exp/kolhab.png",
                "start": "09/2021",
                "end": "10/2022",
                "feats": [
                    "Led development team in building various web applications including two e-commerce websites and one learning management system.",
                    "Discussed project progress with customers, collected feedback on different stages and directly addressed concerns.",
                    "Developed unit test cases for testing and automation.",
                    "Reviewed code, debugged problems, and corrected issues."
                ]
            },
            {
                "company": "Richfill Enterprises",
                "title": "Fullstack Developer",
                "logo": "./uploads/exp/richfill.jpg",
                "start": "08/2022",
                "end": {
                    "label": "Present",
                    "dateTime": 2023
                },
                "feats": [
                    "Resolved scalability problems and addressed security concerns.",
                    "Designed client-side and server-side architecture and implemented cloud-based back end solutions.",
                    "Authored code in PHP and JavaScript within Laravel framework."
                ]
            },
            {
                "company": "DesignIT Africa",
                "title": "Frontend Developer",
                "logo": "./uploads/exp/designit.jpg",
                "start": "08/2020",
                "end": "12/2021",
                "feats": [
                    "Worked with team members to design the organization's website.",
                    "Researched emerging web technologies and trends for possible incorporation into sites.",
                    "Coded using HTML, CSS and JavaScript to develop features for both mobile and desktop platforms."

                ]
            },
            {
                "company": "TheAdeyemiOlayinka",
                "title": "Freelance Web Developer",
                "logo": "./uploads/exp/tao.jpg",
                "start": "10/2018",
                "end": "01/2020",
                "feats": [
                    "Oversaw technical issues and troubleshooting requests to resolve user problems.",
                    "Implemented Google-based SEO and ad campaigns to meet budget specifications.",
                    "Collaborated with marketing, representing web team to establish project goals, projections and milestones.",
                    "Applied emerging technologies to update and maintain site applicability.",
                    "Pulled from PHP, SQL, JavaScript, and other back-end library knowledge to bolster programming resources.",
                    "Designed, implemented and monitored web pages, plugins and functionality for continuous improvement."
                ]
            }
        ],
        "education": [
            {
                "degree": "Bachelor of Science: Mechatronics Engineering",
                "school": "Bells Univeristy of Technology",
                "logo": "./uploads/exp/bells.jpg",
                "start": "11/2022",
                "end": {
                    "label": "Present",
                    "dateTime": 2023
                },
                "feats": [

                ]
            },
            {
                "degree": "High School Diploma",
                "school": "Most High College",
                "logo": "./uploads/exp/mhc.jpg",
                "start": "09/2016",
                "end": "07/2022",
                "feats": [

                ]
            }
        ]
    };

    useEffect(() => {
        fetch('/data/resume.json')
            .then((response) => response.json())
            .then((data) => setJsonData(data))
            .catch((error) => console.error('Error fetching Data JSON:', error));
    }, []);

    if (jsonData != null) {
        resume = jsonData;
    }
    return (
        <>
            <SEO
                title='Resume | TheAdeyemiOlayinka'
                description='A timeline of my notable working experiences in the tech world.'
                name='TheAdeyemiOlayinka.'
                type='profile' />
            <SimpleLayout
                title="A timeline of my notable working experiences in the tech world."
                intro="Explore my professional journey and accomplishments through the timeline below. I've navigated the digital landscape, honed my skills, and contributed to diverse projects. From work experiences to educational milestones, this timeline tells my story in the tech world."
            >
                <center><h1 className="text-base text-zinc-800 dark:text-zinc-100 font-size-30px" >Work History</h1></center>
                <VerticalTimeline>
                    {resume['work'].map((work) => (
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            // contentStyle={{ background: '#c01cc0', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  #c01cc0' }}
                            date={`${work.start.label ?? work.start} - ${work.end.label ?? work.end
                                }`}
                            iconStyle={{ background: 'transparent', color: '#fff' }}
                            // iconStyle={{ background: '#c01cc0', color: '#fff' }}
                            icon={<WorkIcon logo={work.logo}/>}
                        >
                            <h2 className="vertical-timeline-element-title">{work.title}</h2>
                            <h5 className="vertical-timeline-element-subtitle mb-2">{work.company}</h5>
                            <ul className="res_lul">
                                {work.feats.map((feat) => (
                                    <li className="res_lul">
                                        {feat}
                                    </li>
                                ))}
                            </ul>
                        </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
                <br /><br /><br /><br /><br />
                <center><h1 className="text-base text-zinc-800 dark:text-zinc-100 font-size-30px" >Education</h1></center>
                <VerticalTimeline>
                    {resume['education'].map((education) => (
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            // contentStyle={{ background: '#c01cc0', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  #c01cc0' }}
                            date={`${education.start.label ?? education.start} - ${education.end.label ?? education.end
                                }`}
                            iconStyle={{ background: 'transparent', color: '#fff' }}
                            // iconStyle={{ background: '#c01cc0', color: '#fff' }}
                            icon={<WorkIcon logo={education.logo}/>}
                        >
                            <h2 className="vertical-timeline-element-title">{education.degree}</h2>
                            <h5 className="vertical-timeline-element-subtitle mb-2">{education.school}</h5>
                            <ul className="res_lul">
                                {education.feats.map((feat) => (
                                    <li className="res_lul">
                                        {feat}
                                    </li>
                                ))}
                            </ul>
                        </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
            </SimpleLayout>
        </>
    )
}
