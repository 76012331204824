import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';

import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";

//  Routes
import Layout from "./layouts/v1";
import IndexPage from "./routes/index";
import AboutPage from "./routes/about";
import ResumePage from "./routes/resume";
import ProjectsPage from "./routes/projects";
import SkillsPage from "./routes/skills";
import ArticlesPage from "./routes/articles";
//  End Routes

import StandaloneError from "./components/StandaloneError";
import Error from "./components/Error";
import "./styles/full-tailwind.css";
import "./styles/index.scss";
import { HelmetProvider } from 'react-helmet-async';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <StandaloneError />,
    children: [
      {
        errorElement: <Error />,
        children: [
          {
            index: true,
            element: <IndexPage />,
          },
          {
            path: '/about',
            element: <AboutPage />,
          },
          {
            path: '/articles',
            element: <ArticlesPage />,
          },
          {
            path: '/projects',
            element: <ProjectsPage />,
          },
          {
            path: '/resume',
            element: <ResumePage />,
          },
          {
            path: '/skills',
            element: <SkillsPage />,
          }
        ],
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize("G-SF0CEW83GQ");
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </React.StrictMode>
);
const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}
reportWebVitals(SendAnalytics);

serviceWorker.register();
