import { Header } from "./Header";
import { Footer } from "./Footer";
import { Container } from './Container'
import errorImage from '../static/images/photos/error.png'
import { useRouteError } from "react-router-dom";

export default function StandaloneError() {
    const error = useRouteError();
    console.error(error);
    return (
        <>
            <div className="fixed inset-0 flex justify-center sm:px-8">
                <div className="flex w-full max-w-7xl lg:px-8">
                    <div className="w-full bg-white ring-1 ring-zinc-100 dark:bg-zinc-900 dark:ring-zinc-300/20" />
                </div>
            </div>
            <div className="relative">
                <Header />
                <main>
                    <Container className="mt-16 sm:mt-32">
                        <div className="grid-cols-1 gap-y-16 lg:grid-cols-1 justify-center items-center flex flex-col lg:gap-y-12">
                            <div className="lg">
                                <div className="max-w-xs px-2.5 lg:max-w-none">
                                    <img
                                        src={errorImage}
                                        alt=""
                                        sizes="(min-width: 1024px) 32rem, 20rem"
                                        className="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800"
                                    />
                                </div>
                            </div>
                            <div className="bg-red-300 rounded-xl py-2 px-5"><h2 className="text-lg text-bold text-black-800 dark:text-black-800"> Oops! You've run into a { error.status ?? 'undefined' } error</h2></div>
                        </div>
                    </Container>

                </main>
                <Footer />
            </div>
        </>
    );
}