import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import { Button } from './../components/Button'
import { Card } from './../components/Card'
import { Container } from './../components/Container'
import SEO from './../components/SEO'
import {
    TwitterIcon,
    InstagramIcon,
    GitHubIcon,
    LinkedInIcon,
} from './../components/SocialIcons'
import image1 from './../static/images/photos/image-1.jpg'
import image2 from './../static/images/photos/image-2.jpg'
import image3 from './../static/images/photos/image-3.jpg'
import image4 from './../static/images/photos/image-4.jpg'
import image5 from './../static/images/photos/image-5.jpg'
import { formatDate } from './../classes/formatDate'

import jsonp from 'jsonp';
import { client, MY_PUBLICATIONS } from '../classes/gqlClient';

function MailIcon(props) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            aria-hidden="true"
            {...props}
        >
            <path
                d="M2.75 7.75a3 3 0 0 1 3-3h12.5a3 3 0 0 1 3 3v8.5a3 3 0 0 1-3 3H5.75a3 3 0 0 1-3-3v-8.5Z"
                className="fill-zinc-100 stroke-zinc-400 dark:fill-zinc-100/10 dark:stroke-zinc-500"
            />
            <path
                d="m4 6 6.024 5.479a2.915 2.915 0 0 0 3.952 0L20 6"
                className="stroke-zinc-400 dark:stroke-zinc-500"
            />
        </svg>
    )
}

function BriefcaseIcon(props) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            aria-hidden="true"
            {...props}
        >
            <path
                d="M2.75 9.75a3 3 0 0 1 3-3h12.5a3 3 0 0 1 3 3v8.5a3 3 0 0 1-3 3H5.75a3 3 0 0 1-3-3v-8.5Z"
                className="fill-zinc-100 stroke-zinc-400 dark:fill-zinc-100/10 dark:stroke-zinc-500"
            />
            <path
                d="M3 14.25h6.249c.484 0 .952-.002 1.316.319l.777.682a.996.996 0 0 0 1.316 0l.777-.682c.364-.32.832-.319 1.316-.319H21M8.75 6.5V4.75a2 2 0 0 1 2-2h2.5a2 2 0 0 1 2 2V6.5"
                className="stroke-zinc-400 dark:stroke-zinc-500"
            />
        </svg>
    )
}

function ArrowDownIcon(props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" aria-hidden="true" {...props}>
            <path
                d="M4.75 8.75 8 12.25m0 0 3.25-3.5M8 12.25v-8.5"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

function Article({ article }) {
    return (
        <Card as="article">
            <Card.Title isA="true" href={`https://theadeyemiolayinka.hashnode.dev/${article.slug}`}>
                {article.title}
            </Card.Title>
            <Card.Eyebrow as="time" dateTime={article.publishedAt} decorate>
                {formatDate(article.publishedAt)}
            </Card.Eyebrow>
            <Card.Description>{article.brief}</Card.Description>
            <Card.Cta>Read article</Card.Cta>
        </Card>
    )
}

function SocialLink({ icon: Icon, ...props }) {
    return (
        <Link className="group -m-1 p-1" {...props}>
            <Icon className="h-6 w-6 fill-zinc-500 transition group-hover:fill-zinc-600 dark:fill-zinc-400 dark:group-hover:fill-zinc-300" />
        </Link>
    )
}

function Newsletter() {
    var [email, setEmail] = useState("");
    const onSubmit = e => {
        e.preventDefault();
        const url = process.env.REACT_APP_MAILCHIMP_URL;
        jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, data) => {
            const { msg } = data
            alert(msg);
            setEmail("");
        });
    };

    return (
        <form
            className="rounded-2xl border border-zinc-100 p-6 dark:border-zinc-700/40"
            onSubmit={(e) => onSubmit(e)}
        >
            <h2 className="flex text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                <MailIcon className="h-6 w-6 flex-none" />
                <span className="ml-3">Stay up to date</span>
            </h2>
            <p className="mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                Get notified when I publish something new, and unsubscribe at any time.
            </p>
            <div className="mt-6 flex">
                <input
                    type="email"
                    placeholder="Email address"
                    aria-label="Email address"
                    value={email}
                    onChange={(val) => setEmail(val.target.value)}
                    className="min-w-0 flex-auto appearance-none rounded-md border border-zinc-900/10 bg-white px-3 py-[calc(theme(spacing.2)-1px)] shadow-md shadow-zinc-800/5 placeholder:text-zinc-400 focus:border-purple-500 focus:outline-none focus:ring-4 focus:ring-purple-500/10 dark:border-zinc-700 dark:bg-zinc-700/[0.15] dark:text-zinc-200 dark:placeholder:text-zinc-500 dark:focus:border-purple-400 dark:focus:ring-purple-400/10 sm:text-sm"
                    required
                />
                <Button type="submit" className="ml-4 flex-none">
                    Join
                </Button>
            </div>
        </form>
    )
}

function Resume() {
    const [jsonData, setJsonData] = useState(null);

    let resume = [
        {
            "company": "Veegil Media",
            "title": "Backend Developer",
            "logo": "./uploads/exp/veegil.png",
            "start": "08/23",
            "end": {
                "label": "Present",
                "dateTime": 2023
            }
        },
        {
            "company": "Martech Stores",
            "title": "Fullstack Developer",
            "logo": "./uploads/exp/martech.png",
            "start": "08/22",
            "end": {
                "label": "Present",
                "dateTime": 2023
            }
        },
        {
            "company": "Kolhab Technologies",
            "title": "Fullstack Developer",
            "logo": "./uploads/exp/kolhab.png",
            "start": "09/21",
            "end": "10/22"
        },
        {
            "company": "Richfill Enterprises",
            "title": "Fullstack Developer",
            "logo": "./uploads/exp/richfill.png",
            "start": "08/22",
            "end": {
                "label": "Present",
                "dateTime": 2023
            }
        },
        {
            "company": "DesignIT Africa",
            "title": "Frontend Developer",
            "logo": "./uploads/exp/designit.png",
            "start": "08/20",
            "end": "12/21"
        }
    ];

    useEffect(() => {
        fetch('/data/experience.json')
            .then((response) => response.json())
            .then((data) => setJsonData(data))
            .catch((error) => console.error('Error fetching Data JSON:', error));
    }, []);

    if (jsonData != null) {
        resume = jsonData['experience'];
    }

    return (
        <div className="rounded-2xl border border-zinc-100 p-6 dark:border-zinc-700/40">
            <h2 className="flex text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                <BriefcaseIcon className="h-6 w-6 flex-none" />
                <span className="ml-3">Experience</span>
            </h2>
            <ol className="mt-6 space-y-4">
                {resume.map((role, roleIndex) => (
                    <li key={roleIndex} className="flex gap-4">
                        <div className="relative mt-1 flex h-10 w-10 flex-none items-center justify-center rounded-full shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 dark:border dark:border-zinc-700/50 dark:bg-zinc-800 dark:ring-0">
                            <img src={role.logo} alt="" className="h-7 w-7 rounded-full" loading="lazy" decoding="async" data-nimg="1" />
                        </div>
                        <dl className="flex flex-auto flex-wrap gap-x-2">
                            <dt className="sr-only">Company</dt>
                            <dd className="w-full flex-none text-sm font-medium text-zinc-900 dark:text-zinc-100">
                                {role.company}
                            </dd>
                            <dt className="sr-only">Role</dt>
                            <dd className="text-xs text-zinc-500 dark:text-zinc-400">
                                {role.title}
                            </dd>
                            <dt className="sr-only">Date</dt>
                            <dd
                                className="ml-auto text-xs text-zinc-400 dark:text-zinc-500"
                                aria-label={`${role.start.label ?? role.start} until ${role.end.label ?? role.end
                                    }`}
                            >
                                <time dateTime={role.start.dateTime ?? role.start}>
                                    {role.start.label ?? role.start}
                                </time>{' '}
                                <span aria-hidden="true">—</span>{' '}
                                <time dateTime={role.end.dateTime ?? role.end}>
                                    {role.end.label ?? role.end}
                                </time>
                            </dd>
                        </dl>
                    </li>
                ))}
            </ol>
            <Button href="theadeyemiolayinka.pdf" target="_blank" variant="secondary" className="group mt-6 w-full" isA="true">
                Download CV
                <ArrowDownIcon className="h-4 w-4 stroke-zinc-400 transition group-active:stroke-zinc-600 dark:group-hover:stroke-zinc-50 dark:group-active:stroke-zinc-50" />
            </Button>
        </div>
    )
}

function Photos() {
    let rotations = ['rotate-2', '-rotate-2', 'rotate-2', 'rotate-2', '-rotate-2']

    return (
        <div className="mt-16 sm:mt-20">
            <div className="-my-4 flex justify-center gap-3 overflow-hidden py-4 sm:gap-5">
                <div className="tao-owl-carousel owl-carousel flex justify-center gap-3 sm:gap-5">
                    {[image1, image2, image3, image4, image5].map((image, imageIndex) => (
                        <div
                            key={image.src}
                            className={clsx(
                                'relative aspect-[9/10] w-44 flex-none overflow-hidden rounded-xl bg-zinc-100 dark:bg-zinc-800 sm:w-72 sm:rounded-2xl',
                                rotations[imageIndex % rotations.length]
                            )}
                        >
                            <img
                                src={image}
                                alt=""
                                className="absolute inset-0 h-full w-full object-cover"
                                loading="lazy"
                                decoding="async"
                                data-nimg="1"
                            // sizes="18rem, 11rem"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default function IndexPage() {
    const [articles, setArticles] = useState([]);
    const [hasLoadedArticles, setHasLoadedArticles] = useState(false);
    useEffect(() => {
        client
            .query({
                query: MY_PUBLICATIONS,
            })
            .then((result) => {
                if (!result.data.user.posts) {
                    setHasLoadedArticles(true);
                    return
                }
                else if (!result.data.user.posts.nodes) {
                    setHasLoadedArticles(true);
                    return
                }
                setHasLoadedArticles(true);
                setArticles(result.data.user.posts.nodes.slice(0, 3));
            })
    }, []);
    return (
        <>
            <SEO
                title='TheAdeyemiOlayinka'
                description='I am Olayinka Adeyemi, a professional Fullstack Web and Mobile developer based in Lagos, Nigeria.'
                name='TheAdeyemiOlayinka.'
                type='profile' />
            <Container className="mt-9">
                <div className="max-w-2xl">
                    <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                        <span className="relative z-20 imaged-text">Full-Stack Software Developer and Amateur Mechatronist.</span>
                    </h1>
                    <p className="mt-6 text-base text-zinc-600 dark:text-zinc-400">
                        I am Olayinka Adeyemi, a professional Fullstack Web and Mobile developer based in Lagos, Nigeria.
                    </p>
                    <div className="mt-6 flex gap-6">
                        <SocialLink
                            to="https://twitter.com/the_sage7"
                            aria-label="Follow on Twitter"
                            icon={TwitterIcon}
                        />
                        <SocialLink
                            to="https://instagram.com/theadeyemiolayinka"
                            aria-label="Follow on Instagram"
                            icon={InstagramIcon}
                        />
                        <SocialLink
                            to="https://github.com/theadeyemiolayinka"
                            aria-label="Follow on GitHub"
                            icon={GitHubIcon}
                        />
                        <SocialLink
                            to="https://linkedin.com/in/theadeyemiolayinka"
                            aria-label="Follow on LinkedIn"
                            icon={LinkedInIcon}
                        />
                    </div>
                </div>
            </Container>
            <Photos />
            <Container className="mt-24 md:mt-28">
                <div className="mx-auto grid max-w-xl grid-cols-1 gap-y-20 lg:max-w-none lg:grid-cols-2">
                    <div className="flex flex-col gap-16">
                        {(hasLoadedArticles && articles.length < 1) &&
                            <div className="py-6">
                                <Card.Row as="div" className="justify-center space-y-10">
                                    <center><h2 className="ml-3 mt-1 pt-1 text-base font-semibold text-zinc-800 dark:text-zinc-100">
                                        <Card.HacLink isA="noLink" href="#">No Articles Yet.</Card.HacLink>
                                    </h2></center>
                                </Card.Row>
                            </div>
                        }
                        {(!hasLoadedArticles && articles.length < 1) &&
                            <div className="py-6">
                                <Card.Row as="div" className="justify-center">
                                    <center><h2 className="ml-3 mt-1 pt-1 text-base font-semibold text-zinc-800 dark:text-zinc-100">
                                        <Card.HacLink isA="noLink" href="#">Loading Articles...</Card.HacLink>
                                    </h2></center>
                                </Card.Row>
                            </div>
                        }
                        {articles.map((article) => (
                            <Article key={article.slug} article={article} />
                        ))}
                    </div>
                    <div className="space-y-10 lg:pl-16 xl:pl-24">
                        <Newsletter />
                        <Resume />
                    </div>
                </div>
            </Container>
        </>
    );
}