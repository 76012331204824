import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({ title, description, name, type }) {
    return (
        <Helmet>
            { /* Standard metadata tags */}
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name="keywords" content="theadeyemiolayinka,theadeyemiolayinka.com,Adeyemi Olayinka,Olayinka Adeyemi,adeyemi,olayinka,sage,sagex7,the_sage7,__ola.yinka__,olayinka,TheSagex7,martech.ng" />
            <meta name="robots" content="index, follow" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="language" content="English" />
            <meta name="revisit-after" content="7 days" />
            <meta name="author" content="TheAdeyemiOlayinka" />
            { /* End standard metadata tags */}
            { /* Facebook tags */}
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:site_name" content="TheAdeyemiOlayinka" />
            <meta property="og:url" content="https://theadeyemiolayinka.com" />
            <meta property="og:image" content="https://www.dropbox.com/scl/fi/k0ip9kdar50ian82l7ark/placard.png?rlkey=ob9og6yvjan0bolx52utab2mq&raw=1" />
            { /* End Facebook tags */}
            { /* Twitter tags */}
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            { /* End Twitter tags */}
        </Helmet>
    )
}