import React, { useEffect, useState } from 'react';
import { Card } from '../components/Card'
import { SimpleLayout } from '../components/SimpleLayout'
import SEO from './../components/SEO'

function LinkIcon(props) {
    return (
        <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
            <path
                d="M15.712 11.823a.75.75 0 1 0 1.06 1.06l-1.06-1.06Zm-4.95 1.768a.75.75 0 0 0 1.06-1.06l-1.06 1.06Zm-2.475-1.414a.75.75 0 1 0-1.06-1.06l1.06 1.06Zm4.95-1.768a.75.75 0 1 0-1.06 1.06l1.06-1.06Zm3.359.53-.884.884 1.06 1.06.885-.883-1.061-1.06Zm-4.95-2.12 1.414-1.415L12 6.344l-1.415 1.413 1.061 1.061Zm0 3.535a2.5 2.5 0 0 1 0-3.536l-1.06-1.06a4 4 0 0 0 0 5.656l1.06-1.06Zm4.95-4.95a2.5 2.5 0 0 1 0 3.535L17.656 12a4 4 0 0 0 0-5.657l-1.06 1.06Zm1.06-1.06a4 4 0 0 0-5.656 0l1.06 1.06a2.5 2.5 0 0 1 3.536 0l1.06-1.06Zm-7.07 7.07.176.177 1.06-1.06-.176-.177-1.06 1.06Zm-3.183-.353.884-.884-1.06-1.06-.884.883 1.06 1.06Zm4.95 2.121-1.414 1.414 1.06 1.06 1.415-1.413-1.06-1.061Zm0-3.536a2.5 2.5 0 0 1 0 3.536l1.06 1.06a4 4 0 0 0 0-5.656l-1.06 1.06Zm-4.95 4.95a2.5 2.5 0 0 1 0-3.535L6.344 12a4 4 0 0 0 0 5.656l1.06-1.06Zm-1.06 1.06a4 4 0 0 0 5.657 0l-1.061-1.06a2.5 2.5 0 0 1-3.535 0l-1.061 1.06Zm7.07-7.07-.176-.177-1.06 1.06.176.178 1.06-1.061Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default function ProjectsPage() {
    const [jsonData, setJsonData] = useState(null);
    
    let projects = [
        {
            "name": "Furures",
            "description":
                "A crypto-currency trading web platform for all types of modern crypto trading.",
            "link": {
                "href": "https://furures.com",
                "label": "furures.com"
            },
            "logo": "./uploads/prj/ic-furures.png",
            "image": "./uploads/prj/im-furures.jpg"
        },
        {
            "name": "Martech.ng",
            "description":
                "An e-commerce system. Martech.ng - a one stop shop for your daily needs.",
            "link": {
                "href": "https://martech.ng",
                "label": "martech.ng"
            },
            "logo": "./uploads/prj/ic-martech.png",
            "image": "./uploads/prj/im-martech.jpg"
        },
        {
            "name": "The Palm Academy",
            "description":
                "A multi-topic learning management system.",
            "link": {
                "href": "https://thepalm.academy",
                "label": "thepalm.academy"
            },
            "logo": "./uploads/prj/ic-tpa.png",
            "image": "./uploads/prj/im-tpa.jpg"
        },
        {
            "name": "Basefex",
            "description":
                "A website for fast USDT future trading with profitable returns.",
            "link": {
                "href": "https://basefex.co",
                "label": "basefex.co"
            },
            "logo": "./uploads/prj/ic-basefex.png",
            "image": "./uploads/prj/im-basefex.jpg"
        },
        {
            "name": "Chowopa",
            "description":
                "A multi-restaurant food delivery SaaS website.",
            "link": {
                "href": "https://chowopa.com",
                "label": "chowopa.com"
            },
            "logo": "./uploads/prj/ic-chowopa.png",
            "image": "./uploads/prj/im-chowopa.jpg"
        },
        {
            "name": "Haqqman",
            "description":
                "A brand promotion and web dvelopment company website.",
            "link": {
                "href": "https://haqqman.com",
                "label": "haqqman.com"
            },
            "logo": "./uploads/prj/ic-haqqman.png",
            "image": "./uploads/prj/im-haqqman.jpg"
        },
        {
            "name": "Hire9jaChef",
            "description":
                "A hiring platform for local chefs and catering service providers.",
            "link": {
                "href": "https://hire9jachef.com",
                "label": "hire9jachef.com"
            },
            "logo": "./uploads/prj/ic-h9c.png",
            "image": "./uploads/prj/im-h9c.jpg"
        },
        {
            "name": "DesignIT Africa",
            "description":
                "A community that aims at promoting tech awareness in teens and youths.",
            "link": {
                "href": "https://designitafrica.org",
                "label": "designitafrica.org"
            },
            "logo": "./uploads/prj/ic-dita.png",
            "image": "./uploads/prj/im-dita.jpg"
        }
    ]
    
    useEffect(() => {
        fetch('/data/projects.json')
            .then((response) => response.json())
            .then((data) => setJsonData(data))
            .catch((error) => console.error('Error fetching Data JSON:', error));
    }, []);

    if (jsonData != null) {
        projects = jsonData['projects'];
    }
    return (
        <>
            <SEO
                title='Projects | TheAdeyemiOlayinka'
                description='Some projects TheAdeyemiOlayinka has added to the tech community.'
                name='TheAdeyemiOlayinka.'
                type='profile' />
            <SimpleLayout
                title="Some projects I have added to the tech community."
                intro="Welcome to My Project Showcase! Over the years, I've poured my heart and soul into countless projects, but these are some of the ones I hold dear. Some of them are open-source, so if something catches your eye, dive into the code and join the journey of improvement!"
            >
                <ul
                    className="grid grid-cols-1 gap-x-12 gap-y-16 sm:grid-cols-2 lg:grid-cols-3"
                >
                    {projects.map((project) => (
                        <Card.Hov as="li" src={project.image} key={project.name} className="relative overflow-hidden group py-2 px-5 rounded-xl">
                            <div className="relative z-10 flex h-12 w-12 items-center justify-center rounded-full shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 dark:border dark:border-zinc-700/50 bg-white dark:bg-zinc-800 dark:ring-0">
                                <img
                                    src={project.logo}
                                    alt=""
                                    className="h-8 w-8 rounded-full"
                                    loading="lazy"
                                    decoding="async"
                                    data-nimg="1"
                                    unoptimized
                                />
                            </div>
                            <h2 className="mt-6 text-base font-semibold text-zinc-800 dark:text-zinc-100 group-hover:text-white-600">
                                <Card.Link nobg="true" className="group-hover:text-white transition-all duration-700" isA="true" href={project.link.href}>{project.name}</Card.Link>
                            </h2>
                            <Card.Description className="group-hover:text-white transition-all duration-700">{project.description}</Card.Description>
                            <p className="relative z-10 mt-6 flex text-sm font-medium text-purple-500 transition group-hover:text-slate-50 dark:text-purple-500">
                                <LinkIcon className="h-6 w-6 flex-none" />
                                <span className="ml-2">{project.link.label}</span>
                            </p>
                        </Card.Hov>
                    ))}
                </ul>
            </SimpleLayout>
        </>
    )
}
