import React, { useEffect, useState } from 'react';
import { Card } from '../components/Card'
import { SimpleLayout } from '../components/SimpleLayout'
import SEO from '../components/SEO'


export default function SkillsPage() {
    const [jsonData, setJsonData] = useState(null);

    let skills = [
        {
            "name": "Laravel",
            "image": "https://laravel.com/img/favicon/favicon.ico",
            "start": 2018,
            "link": {
                "href": "https://laravel.com/"
            }
        },
        {
            "name": "React.js",
            "image": "https://react.dev/favicon.ico",
            "start": 2019,
            "link": {
                "href": "https://react.dev/"
            }
        },
        {
            "name": "Nest.js",
            "image": "https://nestjs.com/favicon-32x32.0a29681d.png",
            "start": 2021,
            "link": {
                "href": "https://nestjs.com/"
            }
        },
        {
            "name": "Flutter",
            "image": "https://raw.githubusercontent.com/theadeyemiolayinka/readme-generator/main/public/icons/skills/flutter-colored.svg",
            "start": 2020,
            "link": {
                "href": "https://flutter.dev/"
            }
        },
        {
            "name": "PHP",
            "image": "https://raw.githubusercontent.com/theadeyemiolayinka/readme-generator/main/public/icons/skills/php-colored.svg",
            "start": 2017,
            "link": {
                "href": "https://www.php.net/"
            }
        },
        {
            "name": "Javascript",
            "image": "https://raw.githubusercontent.com/theadeyemiolayinka/readme-generator/main/public/icons/skills/javascript-colored.svg",
            "start": 2017,
            "link": {
                "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript"
            }
        },
        {
            "name": "MySQL",
            "image": "https://raw.githubusercontent.com/theadeyemiolayinka/readme-generator/main/public/icons/skills/mysql-colored.svg",
            "start": 2017,
            "link": {
                "href": "https://mysql.com"
            }
        },
        {
            "name": "Firebase",
            "image": "https://raw.githubusercontent.com/theadeyemiolayinka/readme-generator/main/public/icons/skills/firebase-colored.svg",
            "start": 2020,
            "link": {
                "href": "https://firebase.com"
            }
        },
        {
            "name": "MongoDB",
            "image": "https://www.mongodb.com/favicon.ico",
            "start": 2021,
            "link": {
                "href": "https://www.mongodb.com/"
            }
        },
        {
            "name": "Django",
            "image": "https://www.djangoproject.com/favicon.ico",
            "start": 2021,
            "link": {
                "href": "https://djangoproject.com"
            }
        },
        {
            "name": "Node.js",
            "image": "https://nodejs.org/favicon.ico",
            "start": 2020,
            "link": {
                "href": "https://nodejs.org/"
            }
        },
        {
            "name": "HTML5",
            "image": "https://raw.githubusercontent.com/theadeyemiolayinka/readme-generator/main/public/icons/skills/html5-colored.svg",
            "start": 2017,
            "link": {
                "href": "https://developer.mozilla.org/en-US/docs/Glossary/HTML5"
            }
        }
    ];

    useEffect(() => {
        fetch('/data/skills.json')
            .then((response) => response.json())
            .then((data) => setJsonData(data))
            .catch((error) => console.error('Error fetching Data JSON:', error));
    }, []);

    if (jsonData != null) {
        skills = jsonData['skills'];
    }
    return (
        <>
            <SEO
                title='Skills | TheAdeyemiOlayinka'
                description='My skill set and programming stacks - TheAdeyemiOlayinka'
                name='TheAdeyemiOlayinka.'
                type='profile' />
            <SimpleLayout
                title="Tech stacks and frameworks I use profesionally."
                intro="Discover the diverse set of tools powering my web and mobile projects, and get a glimpse of my digital craftsmanship."
            >
                <ul
                    className="grid grid-cols-1 gap-x-12 gap-y-16 sm:grid-cols-2 lg:grid-cols-3"
                >
                    {skills.map((skill) => (
                        <Card.Row as="li" className="justify-between" key={skill.name}>
                            <div className="flex flex-row items-center items-start">
                                <div className="relative z-10 flex h-12 w-12 items-center justify-center rounded-full bg-white shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 dark:border dark:border-zinc-700/50 dark:bg-zinc-800 dark:ring-0">
                                    <img
                                        src={skill.image}
                                        alt=""
                                        className="h-8 w-8"
                                        loading="lazy"
                                        decoding="async"
                                        data-nimg="1"
                                        unoptimized
                                    />
                                </div>
                                <h2 className="ml-3 mt-1 pt-1 text-base font-semibold text-zinc-800 dark:text-zinc-100">
                                    <Card.HacLink isA="true" href={skill.link.href}>{skill.name}</Card.HacLink>
                                </h2>
                            </div>
                            <Card.Tag>{new Date().getFullYear() - skill.start > 1 ? new Date().getFullYear() - skill.start + " yrs" : new Date().getFullYear() - skill.start + " yr"}</Card.Tag>
                        </Card.Row>
                    ))}
                </ul>
            </SimpleLayout>
        </>
    )
}
