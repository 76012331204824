import { Link } from 'react-router-dom'
import clsx from 'clsx'

function ChevronRightIcon(props) {
  return (
    <svg viewBox="0 0 16 16" fill="none" aria-hidden="true" {...props}>
      <path
        d="M6.75 5.75 9.25 8l-2.5 2.25"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function Card({ as: Component = 'div', className, children }) {
  return (
    <Component
      className={clsx(className, 'group relative flex flex-col items-start')}
    >
      {children}
    </Component>
  )
}

Card.Row = function CardRow({ as: Component = 'div', className, children }) {
  return (
    <Component
      className={clsx(className, 'group relative flex flex-row items-center items-start')}
    >
      {children}
    </Component>
  )
}

Card.Hov = function CardHov({ as: Component = 'div', className, src, children }) {
  return (
    <Component
      className={clsx(className, 'group relative flex flex-col items-start bg-opacity-0')}
    >
      <div className="absolute top-0 left-0 w-full h-full opacity-0 bg-black bg-opacity-30 transition-opacity duration-700 group-hover:opacity-100 z-10"></div>
      <img
        src={src}
        alt=""
        loading="lazy" decoding="async" data-nimg="1"
        className="absolute top-0 left-0 w-full h-full object-cover opacity-0 group-hover:opacity-80 transition-opacity duration-700 z-0"
      />
      <div className="z-20">{children}</div>
      
    </Component>
  )
}

Card.Link = function CardLink({ isA,className, children, ...props }) {
  return (
    <>
      <div className={props.nobg === "true" ? "absolute -inset-y-6 -inset-x-4 z-0 scale-95 opacity-0 transition group-hover:scale-100 group-hover:opacity-100 dark:bg-zinc-800/50 sm:-inset-x-6 sm:rounded-2xl" : "absolute -inset-y-6 -inset-x-4 z-0 scale-95 bg-zinc-50 opacity-0 transition group-hover:scale-100 group-hover:opacity-100 dark:bg-zinc-800/50 sm:-inset-x-6 sm:rounded-2xl"} />
      {isA === "true" && <a target='_blank' {...props}>
        <span className="absolute -inset-y-6 -inset-x-4 z-20 sm:-inset-x-6 sm:rounded-2xl" />
        <span className={clsx(className, "relative z-10")}>{children}</span>
      </a>}
      {isA !== "true" && <Link {...props}>
        <span className="absolute -inset-y-6 -inset-x-4 z-20 sm:-inset-x-6 sm:rounded-2xl" />
        <span className={clsx(className, "relative z-10")}>{children}</span>
      </Link>}
    </>
  )
}

Card.HacLink = function CardHacLink({ isA, className, children, ...props }) {
  return (
    <>
      <div className={props.nobg === "true" ? "absolute -inset-y-6 -inset-x-4 z-0 scale-95 transition group-hover:scale-100 opacity-100 dark:bg-zinc-800/50 sm:-inset-x-6 sm:rounded-2xl" : "absolute -inset-y-6 -inset-x-4 z-0 scale-95 bg-zinc-50 transition group-hover:scale-100 opacity-100 dark:bg-zinc-800/50 sm:-inset-x-6 sm:rounded-2xl"} />
      {isA === "true" && <a target='_blank' {...props}>
        <span className="absolute -inset-y-6 -inset-x-4 z-20 sm:-inset-x-6 sm:rounded-2xl" />
        <span className={clsx(className, "relative z-10")}>{children}</span>
      </a>}
      {isA === "noLink" && <p target='_blank' {...props}>
        <span className="absolute -inset-y-6 -inset-x-4 z-20 sm:-inset-x-6 sm:rounded-2xl" />
        <span className={clsx(className, "relative z-10")}>{children}</span>
      </p>}
      {isA !== "true" && isA !== "noLink" && <Link {...props}>
        <span className="absolute -inset-y-6 -inset-x-4 z-20 sm:-inset-x-6 sm:rounded-2xl" />
        <span className={clsx(className, "relative z-10")}>{children}</span>
      </Link>}
    </>
  )
}

Card.Title = function CardTitle({ as: Component = 'h2', href, isA, children }) {
  return (
    <Component className="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">
      {href ? <Card.Link isA={isA} href={href}>{children}</Card.Link> : children}
    </Component>
  )
}

Card.Description = function CardDescription({ className, children }) {
  return (
    <p className={clsx(className, "relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400")}>
      {children}
    </p>
  )
}

Card.Tag = function CardTag({ className, children }) {
  return (
    <p className={clsx(className, "relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400 rounded-full bg-black dark:bg-white bg-opacity-10 dark:bg-opacity-10 px-2")}>
      {children}
    </p>
  )
}

Card.Cta = function CardCta({ children }) {
  return (
    <div
      aria-hidden="true"
      className="relative z-10 mt-4 flex items-center text-sm font-medium text-purple-500"
    >
      {children}
      <ChevronRightIcon className="ml-1 h-4 w-4 stroke-current" />
    </div>
  )
}

Card.Eyebrow = function CardEyebrow({
  as: Component = 'p',
  decorate = false,
  className,
  children,
  ...props
}) {
  return (
    <Component
      className={clsx(
        className,
        'relative z-10 order-first mb-3 flex items-center text-sm text-zinc-400 dark:text-zinc-500',
        decorate && 'pl-3.5'
      )}
      {...props}
    >
      {decorate && (
        <span
          className="absolute inset-y-0 left-0 flex items-center"
          aria-hidden="true"
        >
          <span className="h-4 w-0.5 rounded-full bg-zinc-200 dark:bg-zinc-500" />
        </span>
      )}
      {children}
    </Component>
  )
}
