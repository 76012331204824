import { ApolloClient, InMemoryCache, gql } from '@apollo/client';

export const client = new ApolloClient({
  uri: process.env.REACT_APP_HASHNODE_URL,
  cache: new InMemoryCache(),
});

export const MY_PUBLICATIONS = gql`
query {
  user(username: "theadeyemiolayinka") {
    posts(page: 1, pageSize: 5) {
      nodes {
        title
        slug
        cuid
        brief
        coverImage {
          attribution
          photographer
        }
        publishedAt
      }
    }
  }
}
`;

export const ARTICLES_PAGE = (page) => gql`
query {
  user(username: "theadeyemiolayinka") {
    posts(page: ${page}, pageSize: 10) {
      nodes {
        title
        slug
        cuid
        brief
        coverImage {
          attribution
          photographer
        }
        publishedAt
      }
    }
  }
}
`;
