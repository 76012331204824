import { Link } from 'react-router-dom'
import clsx from 'clsx'

import { Container } from '../components/Container'
import {
    TwitterIcon,
    InstagramIcon,
    GitHubIcon,
    LinkedInIcon,
    MailIcon,
    // PhoneIcon,
} from '../components/SocialIcons'
import portraitImage from '../static/images/portrait.jpg'
import SEO from './../components/SEO'

function SocialLink({ className, href, children, icon: Icon }) {
    return (
        <li className={clsx(className, 'flex')}>
            <Link
                to={href}
                className="group flex text-sm font-medium text-zinc-800 transition hover:text-purple-500 dark:text-zinc-200 dark:hover:text-purple-500"
            >
                <Icon className="h-6 w-6 flex-none fill-zinc-500 transition group-hover:fill-purple-500" />
                <span className="ml-4">{children}</span>
            </Link>
        </li>
    )
}

export default function AboutPage() {
    return (
        <>
            <SEO
                title='About | TheAdeyemiOlayinka'
                description='I am Olayinka Adeyemi, a professional Fullstack Web and Mobile developer based in Lagos, Nigeria.'
                name='TheAdeyemiOlayinka.'
                type='profile' />
            <Container className="mt-16 sm:mt-32">
                <div className="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
                    <div className="lg:pl-20">
                        <div className="max-w-xs px-2.5 lg:max-w-none">
                            <img
                                src={portraitImage}
                                alt=""
                                sizes="(min-width: 1024px) 32rem, 20rem"
                                className="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800"
                            />
                        </div>
                    </div>
                    <div className="lg:order-first lg:row-span-2">
                        <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                            I'm Olayinka Adeyemi. I live in Lagos, Nigeria - where I build the future.
                        </h1>
                        <div className='tao-typed-strings-el display-none hidden'>
                            <div>
                                <p>
                                    Greetings! I'm Olayinka Adeyemi, a seasoned Fullstack Developer with over six years of professional experience. My journey in the world of software development began with a simple curiosity about the immense potential of computers. It was astonishing to me how this small cuboid could be harnessed to perform almost anything that the human mind could conceive. This curiosity ignited a passion within me—a passion to not only understand but also to master the art of commanding computers to fulfill my creative visions.
                                </p>

                                <p>
                                    My voyage into the realm of software engineering commenced shortly after my tenth birthday when I embarked on my coding adventures with Java as my guiding star. Since then, I have embarked on a continuous learning journey, expanding my horizons by mastering various programming languages and frameworks. My primary mission has always been to leverage these technical skills to craft innovative solutions to real-world problems.
                                </p>

                                <p>
                                    Beyond my dedication to programming, I am a devout Christian, and my faith plays a central role in guiding my principles and values. But if there's anything that rivals my passion for coding, it's the captivating world of physics and engineering. I derive great satisfaction from unraveling the intricacies of how objects function and their intricate relationships with physical quantities. This pursuit of understanding the fundamental principles governing our universe enriches my life and informs my work as a developer.
                                </p>

                                <p>
                                    In a nutshell, I'm not just a developer; I'm a curious explorer of both the digital and physical realms. I invite you to join me on this journey of discovery as we bridge the gap between human creativity and technological innovation.
                                </p>

                                <p>
                                    Thank you for visiting my portfolio, and please feel free to explore my projects and get in touch if you have any inquiries or exciting collaboration opportunities.
                                </p>
                            </div>
                        </div>
                        <div className="tao-typed-target mt-6 space-y-7 text-base text-zinc-600 dark:text-zinc-400">
                        </div>
                    </div>
                    <div className="lg:pl-20">
                        <ul>
                            <SocialLink href="https://twitter.com/the_sage7" icon={TwitterIcon}>
                                Follow on Twitter
                            </SocialLink>
                            <SocialLink href="https://instagram.com/theadeyemiolayinka" icon={InstagramIcon} className="mt-4">
                                Follow on Instagram
                            </SocialLink>
                            <SocialLink href="https://github.com/theadeyemiolayinka" icon={GitHubIcon} className="mt-4">
                                Follow on GitHub
                            </SocialLink>
                            <SocialLink href="https://linkedin.com/in/theadeyemiolayinka" icon={LinkedInIcon} className="mt-4">
                                Follow on LinkedIn
                            </SocialLink>
                            <SocialLink
                                href="mailto:hi@theadeyemiolayinka.com"
                                icon={MailIcon}
                                className="mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40"
                            >
                                hi@theadeyemiolayinka.com
                            </SocialLink>
                        </ul>
                    </div>
                </div>
            </Container>
        </>
    )
}
